@charset "utf-8";

/* Default */
@import "__normalize";
@import "__mixin";

/* Adjust if you needed */
@import "_variable";
@import "_default";
@import "_zindex";

/* [ Sample ] */
// FontSize        - @include fz($size: 14);
// Clearfix        - @extend %clearfix;
// No List Style   - @extend %nolist;

// Width > %       - @include width2p(640, 300);
// Height > %      - @include height2p(500, 300);
// Margin > %      - @include margin2p(640, 20, 5, 20, 5);
// Padding > %     - @include padding2p(640, 20, 5, 20, 5);

// Possition > %   - @include left2p(640, 50);
//                 - @include top2p(640, 50);
//                 - @include right2p(640, 50);
//                 - @include bottom2p(640, 50);

.pc {
	display: block;
}
.sp {
	display: none;
}


/*-----------------------------------------------------*/
// header
/*-----------------------------------------------------*/

.header {
	width: 100%;
	height: 100px;
	
	@include e(_inner) {
		width: 1000px;
		padding: 0 10px;
		margin: 0 auto;
		
		h1 {
			margin: 0;
			padding: 0;
			line-height: 1;
			
			a {
				display: inline-block;
				margin-top: 33px;
				
				&:hover {
					opacity: .5;
				}
			}
		}
	}
}


/*-----------------------------------------------------*/
// nav
/*-----------------------------------------------------*/

.nav {
	width: 100%;
	min-width: 1000px;
	border-top: 1px solid #000;
	//border-bottom: 1px solid #000;
	
	ul {
		display: flex;
		justify-content: center;
		
		li {
			list-style: none;
			padding: 0;
			
			a {
				vertical-align: bottom;
				display: block;
				height: 60px;
				padding: 15px 40px 0 40px;
				text-decoration: none;
				line-height: 1;
				text-align: center;
				background: #fff;
				
				dl {
					dt {
						color: #000;
						@include fz($size: 22);
						font-family: $font-din;
						letter-spacing: .03em;
					}
					
					dd {
						color: #000;
						@include fz($size: 10);
					}
				}
				
				&:hover {
					background: #000;
					dl {
						dt {
							color: #fff;
						}
						dd {
							color: #fff;
						}
					}
				}
			}

			&.active {
				a {
					background: #000;
					dl {
						dt {
							color: #fff;
						}
						dd {
							color: #fff;
						}
					}
				}
			}
		}
	}
}


/*-----------------------------------------------------*/
// main
/*-----------------------------------------------------*/

.main {
}


/*-----------------------------------------------------*/
// visual
/*-----------------------------------------------------*/

.visual {
	width: 100%;
	min-width: 1000px;
	height: 570px;
	background: url(../images/visual.png) no-repeat center center #000;
	background-size: auto 570px;
	text-indent: -5000px;
}


/*-----------------------------------------------------*/
// headline
/*-----------------------------------------------------*/

.headline {
	width: 100%;
	min-width: 1000px;
	height: 200px;
	background: url(../images/head.png) no-repeat center center #000;
	background-size: auto 200px;
	
	h1 {
		width: 980px;
		margin: 0 auto;
		line-height: 240px;
		font-family: $font-din;
		@include fz($size: 90);
		color: #fff;
		letter-spacing: .05em;
	}
}


/*-----------------------------------------------------*/
// news
/*-----------------------------------------------------*/

.news {
	width: 1000px;
	padding: 50px 10px 0 10px;
	margin: 0 auto 60px auto;
	
	@include e(_ttl) {
		h2 {
			margin: 0;
			line-height: 0.8;
			
			span {
				display: inline-block;
				@include fz($size: 42);
				font-family: $font-din;
				font-weight: normal;
				letter-spacing: .04em;
				border-bottom: 2px solid #000;
			}
		}
	}
	
	@include e(_detail) {
		@include e(_ttl) {
			border-bottom: 2px solid #000;
			margin-bottom: 35px;
			
			h2 {
				@include fz($size: 26);
				font-weight: 500;
				margin-bottom: 5px;
				line-height: 1.4;
				color: #000;
							
				span {
					display: inline-block;
					background: #e60012;
					color: #fff;
					@include fz($size: 16);
					margin-left: 15px;
					padding: 3px 15px 3px 15px;
					vertical-align: top;
					margin-top: 5px;
				}
			}
			.date {
				@include fz($size: 16);
				margin-bottom: 10px;
			}
		}
		@include e(_body) {
			@include fz($size: 16);
			line-height: 1.9;
			margin-bottom: 70px;
			
			p {
				@include fz($size: 16);
				margin-bottom: 20px;
			}
			
			img {
				max-width: 100%;
				height: auto;
			}
		}
		@include e(_btn) {
			text-align: center;
			
			a {
				display: inline-block;
				padding: 15px 70px;
				color: #fff;
				text-decoration: none;
				@include fz($size: 16);
				font-weight: 500;
				background: url(../images/more_mark.png) 90% center no-repeat #000;
				background-size: 8px;
				
				&:hover {
				background: url(../images/more_mark.png) 93% center no-repeat #555;
				background-size: 8px;
				}
			}
		}
	}
	
	@include e(_list) {
		margin-bottom: 30px;
		
		ul {
			li {
				list-style: none;
				padding: 30px 0;
				border-bottom: 1px solid #e5e5e5;
				
				&:last-child {
					border: none;
				}
				
				a {
					display: flex;
					width: 100%;
					align-items: center;
					text-decoration: none;
					line-height: 1.5;
					
					&:hover {
						opacity: .5;
					}
					
					dl {
						flex: 1;
						dt {
							@include fz($size: 18);
							font-weight: 500;
							color: #000;
							margin-bottom: 5px;
							
							span {
								display: inline-block;
								background: #e60012;
								color: #fff;
								@include fz($size: 13);
								margin-left: 15px;
								padding: 3px 15px 3px 15px;
								vertical-align: top;
								margin-top: 1px;
							}
						}
						dd {
							@include fz($size: 15);
							color: #555;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
		
	@include e(_date) {
		width: 110px;
		@include fz($size: 14);
		color: #555;
		font-weight: 500;
	}
		
	@include e(_more) {
		text-align: center;
		
		a {
			display: inline-block;
			padding: 15px 70px;
			color: #fff;
			text-decoration: none;
			@include fz($size: 16);
			font-weight: 500;
			background: url(../images/more_mark.png) 90% center no-repeat #000;
			background-size: 8px;
			
			&:hover {
			background: url(../images/more_mark.png) 93% center no-repeat #555;
			background-size: 8px;
			}
		}
	}
}


/*-----------------------------------------------------*/
// goods
/*-----------------------------------------------------*/

.goods {
	width: 1000px;
	padding: 50px 10px 0 10px;
	margin: 0 auto 60px auto;
	
	@include e(_note) {
		@include fz($size: 15);
		color: #555;
		line-height: 1.6;
		margin-bottom: 30px;
	}
	
	@include e(_list) {
		margin-bottom: 50px;
		
		@include e(_data) {
			width: 620px;
		
			p {
				@include fz($size: 15);
				color: #555;
				margin-bottom: 20px;
			}
		}
		
		@include e(_img) {
			width: 315px;
			
			div {
				margin-bottom: 10px;
			}
			
			img {
				width: 100%;
				height: auto;
				vertical-align: bottom;
			}
		}
		
		ul {
			width: 100%;
			
			li {
				width: 100%;
				border-bottom: 1px solid #e5e5e5;
				padding: 35px 0;
				list-style: none;
				display: flex;
				justify-content: space-between;
				
				&:first-child {
					border-top: 1px solid #e5e5e5;
				}
				
				&:last-child {
					border-bottom: none;
				}
				
				.data__ttl {
					@include fz($size: 24);
					font-weight: 500;
					color: #000;
					margin-bottom: 15px;
				}
				
				.data__wrap {
					margin-bottom: 20px;
				}
				
				.data {
					display: flex;
					@include fz($size: 15);
					font-weight: 500;
					line-height: 1.8;
					color: #555;
					margin-bottom: 3px;
					
					.label {
						padding-top: 2px;
						span {
							white-space: nowrap;
							display: block;
							background: #000;
							color: #fff;
							@include fz($size: 13);
							margin-right: 10px;
							padding: 0 10px;
							border-radius: 2px;
						}
					}
					.body {
						flex: 1;
					}
				}
			}
		}
	}
	
	@include e(_howto) {
		margin-bottom: 50px;
		border: 4px solid #e5e5e5;
		padding: 35px 40px 20px 40px;
		line-height: 1.8;
		
		.howto {
			
			img {
				border: 1px solid #899cc0;
				width: 596px;
				height: auto;
			} 
		}
		
		h2 {
			@include fz($size: 20);
			background: url(../images/icon_attention.png) no-repeat left center;
			background-size: 30px;
			color: #000;
			margin: 0 0 25px 0;
			padding-left: 40px;
		}
		
		h3 {
			@include fz($size: 16);
			font-weight: 600;
			margin-bottom: 20px;
		}
		
		p {
			@include fz($size: 15);
			color: #555;
			margin-bottom: 20px;
		}
		
		ul,ol {
			padding-left: 20px;
			margin-bottom: 20px;
			
			li {
				@include fz($size: 15);
				color: #555;
			}
		}
		
		ul {
			li {
				display: flex;
				
				span.label {
					display: inline-block;
					width: 80px;
				}
			}
		}
	}
}


/*-----------------------------------------------------*/
// attention
/*-----------------------------------------------------*/

.attention {
	width: 1000px;
	padding: 50px 10px 0 10px;
	margin: 0 auto 60px auto;
	
	@include e(_underconstruction) {
		padding: 50px 0 100px 0;
		text-align: center;
		
		dl {
			dt {
				@include fz($size: 50);
				font-family: $font-din;
				line-height: 1;
			}
			dd {
				@include fz($size: 16);
				font-weight: 500;
				color: #666;
			}
		}
	}
}


/*-----------------------------------------------------*/
// schedule
/*-----------------------------------------------------*/

.schedule {
	width: 1000px;
	padding: 50px 10px 0 10px;
	margin: 0 auto 60px auto;
	
	@include e(_note) {
		@include fz($size: 15);
		color: #555;
		line-height: 1.6;
		margin-bottom: 10px;
	}
	
	@include e(_wrap) {
		display: flex;
		justify-content: space-between;
	}
	
	@include e(_all) {
		width: 210px;
		h3 {
			@include fz($size: 15);
			font-weight: 500;
			margin-bottom: 10px;
			border-bottom: 1px solid #e5e5e5;
			line-height: 40px;
		}
		ul {
			padding: 0;
			margin: 0;
			
			li {
				list-style: none;
				margin-bottom: 7px;
				
				a {
					@include fz($size: 14);
					text-decoration: none;
					color: #333;
					border-bottom: 1px solid rgba(0,0,0,0);
					cursor: default;
					
					&:hover {
						border-bottom: 1px solid rgba(0,0,0,0);
					}
					
					&.on {
						cursor: pointer;
						&:hover {
							color: #000;
							border-bottom: 1px solid rgba(0,0,0,1);
						}
					}
					
					span {
						display: inline-block;
						width: 65px;
					}
				}
			}
		}
	}
	
	@include e(_list) {
		width: 720px;
		margin-bottom: 50px;
		padding-top: 40px;
		
		ul {
			width: 100%;
			
			li {
				width: 100%;
				border-bottom: 1px solid #e5e5e5;
				padding: 25px 0;
				list-style: none;
				
				&:first-child {
					border-top: 1px solid #e5e5e5;
				}
				
				&:last-child {
					border-bottom: none;
				}
			}
		}
		
		@include e(_ttl) {
			@include fz($size: 20);
			font-weight: 500;
			margin-bottom: 10px;
			
			span {
				@include fz($size: 14);
				display: inline-block;
				margin-left: 15px;
				background: #e60012;
				padding: 3px 15px;
				color: #fff;
				vertical-align: top;
				margin-top: 2px;
			}
		}
		
		@include e(_data) {
			@include fz($size: 15);
			font-weight: 500;
			line-height: 2;
			color: #555;
			
			span {
				display: inline-block;
				background: #000;
				color: #fff;
				@include fz($size: 13);
				margin-right: 10px;
				padding: 0 10px;
				border-radius: 2px;
			}
			
			a {
				color: #555;
				text-decoration: none;
				border-bottom: 1px solid rgba(0,0,0,0);
				
				&:hover {
					color: #000;
					border-bottom: 1px solid rgba(0,0,0,1);
				}
			}
		}
	}
}


/*-----------------------------------------------------*/
// footer
/*-----------------------------------------------------*/

.footer {
	
	.btns {
		width: 1000px;
		padding: 0 10px;
		margin: 0 auto 50px auto;
	
		ul {
			width: 980px;
			display: flex;
			justify-content: space-between;
			
			li {
				list-style: none;
				background: url(../images/btns_mark.png) no-repeat 93% center;
				background-size: 31px;
				transition: .3s;
				border-right: 1px solid #e5e5e5;
				padding: 20px 0 20px 20px;
				
				&:hover {
					opacity: .5;
				}
				
				&:first-child {
					padding-left: 0;
				}
				
				&:last-child {
					background: url(../images/btns_mark.png) no-repeat right center;
					background-size: 31px;
					border: none;
				}
				
				&:nth-child(1) {
					width: 318px;
				}
				&:nth-child(2) {
					width: 342px;
				}
				&:nth-child(3) {
					width: 318px;
				}
				
				a {
					display: block;
					text-decoration: none;
				}
				
				dl {
					dt {
						@include fz($size: 15);
						font-weight: 500;
						color: #555;
						margin-bottom: 5px;
					}
					dd {
						@include fz($size: 13);
						font-weight: 500;
						color: #555;
						
						&.label {
							@include fz($size: 30);
							font-family: $font-din;
							font-weight: 400;
							color: #000;
							letter-spacing: .04em;
							line-height: 1.2;
						}
					}
				}
			}
		}
	}

	.banner {
		width: 1000px;
		padding: 0 10px;
		margin: 0 auto 40px auto;
		
		ul {
			width: 980px;
			display: flex;
			justify-content: space-between;
			
			li {
				width: 320px;
				list-style: none;
				
				dl {
					dt {
						border: 1px solid #e5e5e5;
						
						a {
							&:hover {
								opacity: .5;
							}
						}
					}
					dd {
						padding-top: 3px;
						@include fz($size: 12);
						text-align: center;
					}
				}
				
				&:nth-child(3) {
					dl {
						dd {
							color: #0168B7;
						}
					}
				}
			}
		}
	}
	
	.topback {
		text-align: center;
		margin-bottom: 40px;
		
		a {
			&:hover {
				opacity: .5;
			}
		}
	}
	
	.copy {
		width: 100%;
		background: #000;
		text-align: center;
		
		p {
			@include fz($size: 12);
			color: #fff;
			line-height: 100px;
			
			a {
				display: inline-block;
				text-decoration: none;
				color: #fff;
				line-height: 1.3;
				padding: 0 5px;
				
				&:hover {
					background: rgba(255,255,255,.3);
				}
			}
		}
	}
}



/*-----------------------------------------------------*/
// wordpress
/*-----------------------------------------------------*/

blockquote {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
}
.aligncenter {
    display: block;
    margin: 0 auto;
}
.alignright { float: right; }
.alignleft { float: left; }
img[class*="wp-image-"],
img[class*="attachment-"] {
    max-width: 100%;
    height: auto;
}



/*-----------------------------------------------------*/
// wp-pagenavi
/*-----------------------------------------------------*/

.wp-pagenavi {
	padding-top: 40px;
	text-align: center;
	
	span,a {
		display: inline-block;
		margin: 0 5px;
		padding: 6px 11px;
		@include fz($size: 13);
		text-decoration: none;
		color: #000;
	}
	
	.current {
		background: #000;
		color: #fff;
	}
}