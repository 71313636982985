// widthを%に変更
@mixin width2p ( $parentPxWidth, $pxWidth ){
    width: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}

// heightを%に変更
@mixin height2p ($parentPxHeight, $pxHeight){
    height: floor((($pxHeight / $parentPxHeight) * 10000%)) / 100;
}

// marginを%に変更
@mixin margin2p($parentPxWidth, $mTop, $mRight, $mBottom, $mLeft){
    margin : floor((($mTop / $parentPxWidth) * 10000%)) / 100 floor((($mRight / $parentPxWidth) * 10000%)) / 100 floor((($mBottom / $parentPxWidth) * 10000%)) / 100 floor((($mLeft / $parentPxWidth) * 10000%)) / 100;
}

// paddingを%に変更
@mixin padding2p($parentPxWidth, $pTop, $pRight, $pBottom, $pLeft){
    padding : floor((($pTop / $parentPxWidth) * 10000%)) / 100 floor((($pRight / $parentPxWidth) * 10000%)) / 100 floor((($pBottom / $parentPxWidth) * 10000%)) / 100 floor((($pLeft / $parentPxWidth) * 10000%)) / 100;
}

// left値
@mixin left2p($parentPxWidth, $pxLeft){
    left: floor((($pxLeft / $parentPxWidth) * 10000%)) / 100;
}
// top値
@mixin top2p($parentPxHeight, $pxTop){
    top: floor((($pxTop / $parentPxHeight) * 10000%)) / 100;
}
// right値
@mixin right2p($parentPxWidth, $pxRight){
    right: floor((($pxRight / $parentPxWidth) * 10000%)) / 100;
}
// bottom値
@mixin bottom2p($parentPxHeight, $pxBottom){
    bottom: floor((($pxBottom / $parentPxHeight) * 10000%)) / 100;
}


//Element用のミックスイン	親要素のクラス名を継承してデリミターとして"__"を付与
@mixin e($name) {
	@at-root &_#{$name} {
		@content;
	}
}

//Sub用のミックスイン	親要素のクラス名を継承してデリミターとして"_"を付与
@mixin s($name) {
	@at-root &#{$name} {
		@content;
	}
}

//Modifier用のミックスイン	親要素のクラス名を継承してデリミターとして"--"を付与
@mixin m($name) {
	@at-root &-#{$name} {
		@content;
	}
}

// FontSize
@mixin fz($size: 14) {
		font-size: $size + px;
		font-size: ($size / 10) + rem;
}

// Clearfix
%clearfix {
	&:after {
		clear: both;
		content: "";
		display: block;
	}
}

// noliststyle
%nolist {
	list-style: none;
	margin: 0;
	padding: 0;
}

// スマホのナビ
@mixin spnav_btn {
	%spnav_default {
		display: block;
		transition: all .3s;
		position: absolute;
		left: 0;
		width: 100%;
		height: 3px;
		background-color: #000;
		border-radius: 4px;
	}

	@include e(spnav) {
		width: 25px;
		height: 20px;
		display: block;
		position: absolute;
		right: 5%;
		top: 50%;
		z-index: 1001;
		transform: translateY(-50%);
		&:before {
			top: 0;
			content: "";
			@extend %spnav_default;
		}
		&:after {
			bottom: 0;
			content: "";
			@extend %spnav_default;
		}
		span {
			top: 50%;
			transform: translateY(-50%);
			@extend %spnav_default;
		}
		@include m(active) {
			&:before {
				transform: translateY(8.5px) rotate(-45deg);
				background-color: #fff !important;
			}
			&:after {
				transform: translateY(-8.5px) rotate(45deg);
				background-color: #fff !important;
			}
			span {
				opacity: 0;
				background-color: #fff !important;
			}
		}
	}
}


@mixin spnav_content {
	position: fixed;
	z-index: 1000;
	background: rgba(#000,.9);
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: none;
}
